import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";

// Layout
import SecondaryLanding from "../layouts/secondary-landing";

// Components
import HeroChevron from "../hero/hero-chevron";
import BreadcrumbAuto from "../breadcrumb/breadcrumb-auto";
import SEO from "../seo/seo";
import BranchSearch from "../branch-search/branch-search";
import NoLinkCards from "../../components/card/no-link-cards";

// Helpers
import getHeroImgVariables from "../../helpers/getHeroImgVariables";

export const query = graphql`
  query spanishCityHeroQuery(
    $slug: String
    $pathXXL: String
    $pathXL: String
    $pathLG: String
    $pathMD: String
    $pathSM: String
    $pathXS: String
    $pathXXS: String
  ) {
    imgVariableXXL: file(relativePath: { eq: $pathXXL }) {
      ...heroChevronImageFragmentXXL
    }
    imgVariableXL: file(relativePath: { eq: $pathXL }) {
      ...heroChevronImageFragmentXL
    }
    imgVariableLG: file(relativePath: { eq: $pathLG }) {
      ...heroChevronImageFragmentLG
    }
    imgVariableMD: file(relativePath: { eq: $pathMD }) {
      ...heroChevronImageFragmentMD
    }
    imgVariableSM: file(relativePath: { eq: $pathSM }) {
      ...heroChevronImageFragmentSM
    }
    imgVariableXS: file(relativePath: { eq: $pathXS }) {
      ...heroChevronImageFragmentXS
    }
    imgVariableXXS: file(relativePath: { eq: $pathXXS }) {
      ...heroChevronImageFragmentXXS
    }
    checkingImage: file(relativePath: { eq: "cards/thumbnail-checking-030524.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    savingsImage: file(relativePath: { eq: "cards/thumbnail-savings-030524.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    mortgageImage: file(relativePath: { eq: "cards/thumbnail-home-loans-030524.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    cityBranches: strapiBranchCities(Slug: { eq: $slug }) {
      CityName
      PageURL
      Slug
      SEO {
        MetaDescription
        MetaTitle
      }
      branch_locations {
        Address {
          State
        }
      }
    }
  }
`;

const SpanishCityBranchesTemplate = ({ location, data }) => {
  const cityData = data.cityBranches;
  const CityName = cityData.CityName || "Seattle";
  let StateName = cityData.branch_locations[0].Address.State || "Washington";
  const seoStateName = StateName.toLowerCase().replace(/NewMexico/i, "new-mexico");

  StateName = StateName.replace(/NewMexico/, "Nuevo Mexico");

  const SearchData = {
    isSpanish: true,
    showSearchInput: false,
    CityName: CityName,
    StateName: StateName,
    title: CityName
  };

  const pageLocation = { location };

  const metaTitle = "Sucursales en " + CityName + ", " + StateName + " - Best Bank en " + CityName;

  const metaDescription =
    "WaFd Bank le ofrece todos los servicios bancarios que necesita en " +
    CityName +
    ", " +
    StateName +
    ". Visite hoy una de nuestras sucursales bancarias.";

  const SEOData = {
    title: metaTitle,
    meta: [
      {
        name: "title",
        property: "og:title",
        content: metaTitle
      },
      {
        name: "description",
        property: "og:description",
        content: metaDescription
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com" + pageLocation.location.pathname
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content:
          "https://www.wafdbank.com/images/adsearch/cities/og-" + cityData.Slug + "-" + seoStateName + "-052024.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "state-branches-hero-chevron",
    ...getHeroImgVariables(data),
    altText: `Photo of ${CityName}, ${StateName} skyline.`,
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: `Encuentra tu sucursal local de WaFd Bank en ${CityName}, ${StateName}!`
          }
        },
        {
          id: 2,
          button: {
            id: "state-branches-hero-chevron-cta",
            text: "Abrir una cuenta",
            url: "/es/banca-personal/rewards-checking",
            class: "btn-white"
          }
        }
      ]
    }
  };

  const threeCardsData = {
    sectionId: "state-branches-link-cards",
    cards: [
      {
        id: "state-branches-link-card-checking",
        title: "La Cuenta de Cheques Simple, Directa, e Inteligente",
        titleClass: "text-success",
        text: "Tu cuenta debería trabajar duro para tí. Desde herramientas estándar como Bill Pay hasta beneficios y recompensas, encontrarás una cuenta de cheques que se ajuste a tus necesidades. Además, ¡nuestras atractivas tarjetas de débito te ayudarán a gastar con estilo!",
        image: {
          imgVariable: data.checkingImage.childImageSharp.gatsbyImageData,
          altText: "Mujer usa su celular mientras está sentada con las piernas cruzadas en un sofá."
        },
        button: {
          id: "state-branches-link-card-checking-cta",
          text: "Comparar Cuentas",
          url: "/es/banca-personal/cuenta-de-cheques",
          class: "btn-primary btn-block stretched-link",
          containerClass: "mt-auto w-100",
          showIcon: false
        }
      },
      {
        id: "state-branches-link-card-savings",
        title: "Cuentas de Ahorro para Ayudarle a Prosperar",
        titleClass: "text-success",
        text: "Con una variedad de opciones de ahorro, tu dinero puede trabajar más para tí. Ofrecemos varias opciones para satisfacer tus necesidades con la flexibilidad que la vida requiere.",
        image: {
          imgVariable: data.savingsImage.childImageSharp.gatsbyImageData,
          altText: "Una madre y su hijo de vacaciones, estación de tren de Copenhagen."
        },
        button: {
          id: "state-branches-link-card-savings-cta",
          text: "Comparar Cuentas",
          url: "/es/banca-personal/cuentas-de-ahorro",
          class: "btn-primary btn-block stretched-link",
          containerClass: "mt-auto w-100",
          showIcon: false
        }
      },
      {
        id: "state-branches-link-card-mortgage",
        title: "Préstamos Hipotecarios que se Adaptan a tu Vida",
        titleClass: "text-success",
        text: "Estés donde estés en la vida, un préstamo hipotecario debe adaptarse a tus necesidades. Con opciones para compradores de vivienda por primera vez, remodelación, construcción y más, nuestros oficiales de préstamos expertos están aquí para ayudarte.",
        image: {
          imgVariable: data.mortgageImage.childImageSharp.gatsbyImageData,
          altText: "Una feliz pareja sentada en su sillón, riendo."
        },
        button: {
          id: "locations-link-card-mortgage-cta",
          text: "Ver Más",
          url: "/es/banca-personal/prestamos-hipotecarios",
          class: "btn-primary btn-block stretched-link",
          containerClass: "mt-auto w-100",
          showIcon: false
        }
      }
    ]
  };

  const isBestBankState = ["Washington", "Idaho", "Nuevo Mexico"].includes(StateName);

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <BreadcrumbAuto {...pageLocation} />

      <div id="state-branches-wrapper">
        <section id="state-branches-overview-section" className="container">
          <h1>
            WaFd Bank en {CityName}, {StateName}
          </h1>
          <h3>
            Encuentra tu sucursal local del WaFd Bank en {CityName}, {StateName}. WaFd{" "}
            {isBestBankState
              ? "fue elegido como el Mejor Banco en " + StateName
              : "ha sido nominado como el Mejor Banco por Newsweek tres años consecutivos,"}{" "}
            y está aquí para servirte a ti y a nuestras comunidades en 9 estados del oeste para todas tus necesidades
            bancarias. Ya sea que estés buscando una{" "}
            <Link id="blog-abrir-cuenta-bancaria-en-linea-link" to="/es/abrir-cuenta-bancaria-en-linea">
              cuenta bancaria
            </Link>
            , una{" "}
            <Link id="blog-prestamos-hipotecarios-link" to="/es/banca-personal/prestamos-hipotecarios">
              hipoteca para tu hogar
            </Link>{" "}
            o un préstamo para construcción, una línea de crédito con garantía hipotecaria (HELOC), servicios bancarios
            para empresas, herramientas de banca digital y más, WaFd Bank está aquí para ayudarte. Con nuestra
            experiencia en banca en línea y móvil, puedes abrir una cuenta o hacer transacciones bancarias con nosotros
            en cualquier momento y lugar.
          </h3>
        </section>

        <BranchSearch {...SearchData} />
        <NoLinkCards {...threeCardsData} />
      </div>
    </SecondaryLanding>
  );
};

SpanishCityBranchesTemplate.propTypes = {
  branch: PropTypes.object
};

export default SpanishCityBranchesTemplate;
